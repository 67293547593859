import React from "react";
import LanguageProvider from './src/provider/LanguageProvider';
import CurrencyProvider from './src/provider/CurrencyProvider';
import './src/assets/styles/style.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const wrapRootElement = ({ element }) => {
  return <LanguageProvider><CurrencyProvider>{element}</CurrencyProvider></LanguageProvider>
}

export { wrapRootElement };
